<template>
    <v-card color="#F6F9FC" flat class="rounded-0 pt-6">
        <v-container v-if="details">
            <v-card flat v-if="details.themes_status == 3">
                <v-card-title>
                    {{ $t("审核信息") }}
                </v-card-title>
                <v-card color="rgba(255, 34, 34, 0.10)" class="mx-4 pa-4 rounded-0" flat
                    style="border-top: rgba(255, 34, 34, 0.60) solid 3px;">
                    <div>
                        <v-icon color="red">mdi-information-outline</v-icon>
                        <span class="red--text pl-2">{{ $t('审核失败') }}</span>
                        <span class="red--text text-caption">{{ $t('（审核失败请到原创作平台进行编辑后更新）') }}</span>
                    </div>
                    <v-card height="1" color="rgba(255, 34, 34, 0.10)" flat class="my-4"></v-card>
                    <p class="font-weight-bold">{{ $t('失败原因') }}：</p>
                    <div>
                        {{ details.themes_status_text }}
                    </div>
                </v-card>
                <div class="pa-4">
                    <span>{{ $t('申请时间') }}：{{ details.releasetime }}</span>
                    <span class="pl-lg-12 pl-2">{{ $t('审核时间') }}：{{ details.audittime }}</span>
                </div>


            </v-card>
            <v-card flat class="mt-4 pa-4">
                <v-card-title class="pl-0 d-flex justify-space-between">

                    <div class="d-flex align-center">
                        {{ details.name }} 
                        <p class="text-subtitle-1 mb-0 ml-2">
                            <v-chip color="rgba(9, 187, 7, 0.10)" class="success--text" small
                                v-if="details.themes_status == 2" label>{{
            $t('审核通过') }}</v-chip>
                            <v-chip color="rgba(255, 34, 34, 0.10)" class="danger--text" small
                                v-if="details.themes_status == 3" label>{{
            $t('审核失败') }}</v-chip>
                            <v-chip color="rgba(255, 140, 34, 0.10)" class="warning--text" small
                                v-if="details.themes_status == 1" label>{{
            $t('已发布') }}</v-chip>
                            <v-chip color="rgba(87, 139, 255, 0.10)" class="info--text" small
                                v-if="details.themes_status == 0" label>{{
            $t('未发布') }}</v-chip>

                        </p>
                    </div>
                    <v-btn outlined color="primary" depressed  @click="delSelfmoban(details.themes_id)">{{ $t('删除当前模版') }}</v-btn>
                </v-card-title>
                <v-divider></v-divider>
                <v-card flat class="mt-4">

                    <div class="text-center">
                        <img :src="details.cover_img" alt="">
                    </div>


                    <p>{{ details.des }}</p>
                    <v-card color="#f7f7f7" class="px-4 pt-4" flat>
                        <v-card-title primary-title class="px-0 pt-0">
                            {{ $t('主题模板特点') }}
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text>
                            <ul class="px-0">
                                <li v-for="(fitem, fidx) in details.features" :key="fidx" style="list-style: disc;"
                                    class="black--text mb-2">{{ fitem }}</li>
                            </ul>
                        </v-card-text>
                    </v-card>
                    <v-card color="#f7f7f7" class="mt-4" flat>

                        <v-card-text>
                            <v-row>
                                <v-col cols="2"><span class="grey--text text--darken-4">{{ $t('当前版本号') }}</span></v-col>
                                <v-col cols="10" class="grey--text text--darken-4">{{ details.version }} </v-col>

                            </v-row>
                            <v-row>
                                <v-col cols="2"><span class="grey--text text--darken-4">{{ $t('发表时间') }}</span></v-col>
                                <v-col cols="10" class="grey--text text--darken-4">{{ details.createtime }} </v-col>

                            </v-row>
                            <v-row>
                                <v-col cols="2"><span class="grey--text text--darken-4">{{ $t('更新时间') }}</span></v-col>

                                <v-col cols="10" class="grey--text text--darken-4">{{ details.updatetime }}</v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="2"><span class="grey--text text--darken-4">{{ $t('高分辨率') }}</span></v-col>
                                <v-col cols="10" class="grey--text text--darken-4"
                                    v-if="details.is_high_resolution == 1">{{
            $t('是的') }}</v-col>
                                <v-col cols="10" class="grey--text text--darken-4" v-else>{{ $t('不支持') }}</v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="2"><span class="grey--text text--darken-4">{{ $t('可兼容浏览器')
                                        }}</span></v-col>
                                <v-col cols="10" class="grey--text text--darken-4">{{ details.support_browsers
                                    }}</v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="2"><span class="grey--text text--darken-4">{{ $t('布局') }}</span></v-col>
                                <v-col cols="10" class="grey--text text--darken-4">{{ details.layout }}</v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="2"><span class="grey--text text--darken-4">{{ $t('标签') }}</span></v-col>
                                <v-col cols="10" class="grey--text text--darken-4">
                                    <span v-for="item in details.themes_tag_info" :key="item.themes_tag_id">{{ item.name
                                        }}/</span>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-card>
            </v-card>

        </v-container>
        <!-- 发布模板 -->
        <v-dialog v-model="selfObj.show" persistent width="450">
            <v-card>
                <v-card-title class="text-h6">
                    <v-icon color="#faad14" class="mr-2">mdi-information-outline</v-icon>
                    {{ $t('发布模版') }}
                </v-card-title>
                <v-card-text>{{ $t('申请发布模版，taoifyShop会尽快审核，请在个人中心查看结果。') }}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="selfObj.show = false">{{ $t('取消') }}</v-btn>
                    <v-btn color="primary" text @click="confirmSelf">{{ $t('确定') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>
<script>
export default {
    data() {
        return {
            details: null,
            custom_themes_id: 0,
            selfObj: {
                show: false,
                themes_id: 0
            }
        }
    },
    mounted() {
        this.custom_themes_id = this.$route.query.custom_themes_id;
        this.getdetail();

    },
    methods: {
        getdetail() {
            this.$api.template.themes_detail({ custom_themes_id: this.custom_themes_id }).then(res => {
                if (res.status) {
                    this.details = res.data;
                    if (res.data.type == 2) {
                        this.getpaytype();
                    }
                }
            })
        },
        getpaytype() {
            this.$api.template.paytype().then(res => {
                if (res.status) {
                    this.paylist = res.data;

                }
            })
        },
        openDialog(id) {
            this.selfObj.show = true;
            this.selfObj.themes_id = id;;
        },
        //发布模版
        confirmSelf() {
            this.$api.template.themes_release({ themes_id: this.selfObj.themes_id }).then(res => {
                this.selfObj.show = false;
                if (res.status) {
                    this.$Toast({ content: res.msg });
                    this.getdetail();
                } else {
                    this.$Toast({ content: res.msg, type: 'error' });
                }

            })
        },
        delSelfmoban(id) {
            this.$api.template.themes_del({ themes_id: id }).then(res => {
                if (res.status) {
                    this.$Toast({ content: res.msg });
                    this.$router.back();
                } else {
                    this.$Toast({ content: res.msg, type: 'error' });
                }
            })
        },
    }
}
</script>
<style>
.filter-price-input {
    background-color: #F7F7F7;
}

.default-pay {
    border: solid 2px transparent;
}

.checked-pay {
    border: solid 2px var(--v-primary-base);
}
</style>